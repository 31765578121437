import type { Subscription } from '~/typesAuto/apicore/v1'

export default () => {
  const { apiCoreFetch } = useApiFetch()

  return {
    async createSubscription(clientId: number, data: Record<string, unknown>) {
      return await apiCoreFetch<Subscription>(
        `clients/${clientId}/subscriptions`,
        {
          method: 'POST',
          body: data,
        }
      )
    },
    async list(clientId: number) {
      return await apiCoreFetch<Subscription[]>(
        `clients/${clientId}/subscriptions`
      )
    },
  }
}
