<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
const nuxtApp = useNuxtApp()
const route = useRoute()

type AdEvent = {
  detail: {
    divId: string
  }
  type: 'filledAd' | 'unfilledAd'
}

// Stop NuxtLinks missing required params from crashing the page
onErrorCaptured((err, instance) => {
  if (err.message.startsWith('Missing required param')) {
    const linkTo = instance && 'to' in instance ? instance?.to : undefined

    if (import.meta.client) {
      nuxtApp.$sentry.captureException(err, { extra: { linkTo } })
    }

    return false
  }
})

const addAdEventListener = () => {
  window.document.addEventListener('unfilledAd', handleAds, { once: true })
  window.document.addEventListener('filledAd', handleAds, { once: true })
}
const removeAdEventListener = () => {
  window.document.removeEventListener('unfilledAd', handleAds)
  window.document.removeEventListener('filledAd', handleAds)
}

const handleAds = (event: unknown) => {
  const typedEvent = event as AdEvent
  const adContainer = document.getElementById(typedEvent.detail.divId)
  if (adContainer) {
    const existingLabel = document.querySelector(
      `#ad-label-${typedEvent.detail.divId}`
    )

    if (typedEvent.type === 'filledAd') {
      // Add label if not already exists
      if (!existingLabel) {
        const adLabel = document.createElement('div')
        adLabel.dataset.adLabelElement
        adLabel.id = `ad-label-${typedEvent.detail.divId}` // Ensure unique ID for labels
        adLabel.className =
          'text-center text-black text-opacity-50 label-s block'
        adContainer.insertAdjacentElement('afterend', adLabel) // Insert after the div
      }
    } else if (typedEvent.type === 'unfilledAd') {
      // remove label if exist
      if (existingLabel) {
        existingLabel.remove()
      }
    }
  }
}
if (isSite('altinget_no') && import.meta.client) {
  onMounted(() => addAdEventListener())

  watch(
    () => route.fullPath,
    async () => {
      removeAdEventListener()
      await nextTick()
      addAdEventListener()
    }
  )
}
</script>
